import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatwootService {
  constructor() {}
  addWidget() {
    const h =
      document.head ||
      document.getElementsByTagName('head')[0] ||
      document.body;
    const BASE_URL = 'https://chat.codenteam.com';
    const file = '/packs/js/sdk.js';

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = BASE_URL + file;
    s.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).chatwootSDK.run({
        websiteToken: 'bdsQNXgUWx3wedcxCdMaoq6t',
        baseUrl: BASE_URL,
      });
    };
    h.appendChild(s);
  }
}
