import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ScanGaugeComponent } from '@codenteam/ui/scan-gauge/scan-gauge.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';

import { ApiService } from '../../core/api.service';

import { Alert, Report, DastScan } from '@codenteam/portal/graphql';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { MatTooltipDirective } from './mat-tootltip-dummy.directive';

@Component({
  selector: 'codenteam-dast-scan-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    ScanGaugeComponent,
    MatExpansionModule,
    MatTooltipDirective,
    LetDirective,
    ReportHeaderComponent,
  ],
  templateUrl: './dast-scan-report.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DastScanReportComponent implements OnInit {
  @Input()
  uuid: string;
  @Input()
  index: number;
  scan$: Observable<DastScan>;
  elementsBreak: number[];
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  currentDate = new Date();
  gaugeData = [
    { label: '1', value: 1, color: '#6EF140' },
    { label: '2', value: 1, color: '#F6BF10' },
    { label: '3', value: 1, color: '#E8872D' },
    { label: '4', value: 1, color: '#E8642D' },
    { label: '5', value: 1, color: '#CF3E3E' },
  ];
  ngOnInit(): void {
    this.scan$ = this.apiService.singleScan(this.uuid);
  }

  constructor(private apiService: ApiService) {}

  calculateRiskPercentage(alerts: Array<Alert>): number {
    const weights = [0.25, 0.5, 0.75, 1.0];
    let weightedSum = 0;
    for (let i = 0; i < alerts.length; i++) {
      weightedSum += weights[alerts[i].confidence];
    }
    const weightedPercentage = weightedSum / alerts.length;
    const percentege = (weightedPercentage * 180 - 90) * -1;
    return percentege;
  }
  countRisks(alerts: Array<Alert>, risk: number) {
    let totalRisk = 0;
    if (risk === 4) {
      for (let index = 0; index < risk; index++) {
        totalRisk += alerts.filter((item) => item.risk === index).length;
      }
      return totalRisk;
    }
    const number = alerts.filter((item) => item.risk === risk).length;
    return number;
  }
}
