<div
  class="rounded-md bg-slate-900 px-2.5 py-1.5 text-lg font-medium leading-6"
>
  Create a report
</div>
<div class="font-mada mb-9 mt-4 text-xl font-medium leading-7">
  Select to create a report...
</div>
<form
  [formGroup]="form"
  class="mx-auto flex max-w-[42.5rem] flex-col items-center gap-10 sm:gap-20"
>
  <div class="flex flex-col gap-5 sm:flex-row sm:gap-20">
    <mat-form-field
      *ngrxLet="runs$ | async as runs"
      class="w-60"
    >
      <mat-label>Company Analysis Runs</mat-label>
      <mat-select
        formControlName="runsControl"
        multiple
        panelClass="!text-white report-inputs"
      >
        <mat-option
          *ngFor="let run of runs"
          [value]="run.uuid"
          class="!bg-slate-500 !text-sm"
        >
          {{ run.name }}
        </mat-option>
      </mat-select>
      <mat-hint
        class="text-yellow-500"
        *ngIf="runs?.length == 0"
        >No runs. Import new runs to be able to analyze them</mat-hint
      >
    </mat-form-field>

    <mat-form-field
      *ngrxLet="scans$ | async as scans"
      class="w-60"
    >
      <mat-label>DAST Scan</mat-label>
      <mat-select
        formControlName="scansControl"
        multiple
        panelClass="!text-white report-inputs"
      >
        <mat-option
          *ngFor="let scan of scans"
          [value]="scan.uuid"
          class="!bg-slate-500 !text-sm"
          [ngClass]="scan.status === 'Done' ? '' : '!hidden'"
        >
          {{ scan.website }}
        </mat-option>
      </mat-select>
      <mat-hint
        class="text-yellow-500"
        *ngIf="scans?.length == 0 || checkScans(scans)"
        >No DAST scans. Start new DAST scans to be able to analyze
        them</mat-hint
      >
    </mat-form-field>
  </div>
  <div class="hidden w-full flex-col gap-3">
    <div
      class="flex justify-center rounded-xl bg-slate-900 px-3 py-2.5 text-sm"
    >
      Send this Email to...
    </div>
    <div class="flex items-center gap-2 border-b-4 border-slate-900 pb-2">
      <mat-icon>email</mat-icon>
      <input
        type="email"
        placeholder="Email address"
        class="w-full bg-inherit focus:outline-none"
      />
    </div>
  </div>
  <button
    type="submit"
    class="w-[7.625rem] rounded-md bg-rose-500 px-5 py-1"
    [ngClass]="form.invalid ? 'opacity-50' : ''"
    (click)="onSubmit()"
    [disabled]="form.invalid"
    [matTooltip]="form.errors ? form.getError('required') : ''"
  >
    View Report
  </button>
</form>
