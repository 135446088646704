<div
  class="w-full"
  *ngrxLet="{
    account: account$,
    user: userInfo$
  } as context"
>
  <div class="top-center w-full flex-col bg-white text-white">
    <codenteam-report-header
      [account]="context.account"
      [user]="context.user"
    ></codenteam-report-header>
    <div
      class="mx-5 mb-4 flex items-center justify-between rounded-md bg-slate-200 p-3 text-black"
    >
      <div class="flex items-center">
        <mat-icon [style.color]="team.color">circle</mat-icon>
        <p class="ml-2 leading-4">{{ team.name }}</p>
      </div>
      <p class="text-xs">
        Employee ({{ team.profiles.length }}) & Ex.employee ({{
          numberOfExEmployees
        }})
      </p>
    </div>
  </div>
  <div class="flex flex-col bg-white p-5 text-black">
    <div class="mb-4 flex gap-5">
      <div class="flex h-72 w-1/2 flex-col rounded-md bg-slate-200 p-3 pt-2">
        <div class="mb-2 self-start text-lg">Teams Ownership:</div>
        <div
          class="flex flex-col gap-4"
          *ngIf="teamThisMonth && teamPreviousMonth"
        >
          <div class="flex">
            <div class="flex basis-1/2 flex-col items-center justify-start">
              <codenteam-pie-graph
                [showLegend]="false"
                [data]="teamThisMonth"
                class="flex !min-h-80"
              ></codenteam-pie-graph>
              <div class="-mt-40 flex">This Month</div>
            </div>
            <div class="flex basis-1/2 flex-col items-center justify-center">
              <codenteam-pie-graph
                [showLegend]="false"
                [data]="teamPreviousMonth"
                class="flex !min-h-80 flex-1"
              ></codenteam-pie-graph>
              <div class="-mt-40 flex">Previous Month</div>
            </div>
          </div>
          <div class="flex gap-6 self-center">
            <div
              *ngFor="let profile of teamThisMonth"
              class="flex gap-1"
            >
              <mat-icon
                [style.color]="profile.color"
                class="!size-5 !text-base"
                >circle</mat-icon
              >
              <div>{{ profile.name }}</div>
            </div>
          </div>
        </div>
        <div
          *ngIf="spiderTeams"
          class="stretch flex flex-1 place-content-center items-stretch"
        >
          <codenteam-spider-chart
            [teams]="spiderTeams"
            class="flex"
          ></codenteam-spider-chart>
        </div>
      </div>
      <div
        class="flex h-72 w-1/2 flex-col items-center justify-center rounded-md bg-slate-200 p-6"
      >
        <div class="font-mada mb-3 font-medium">Total Number of Risks</div>
        <div class="mb-2 text-5xl font-bold leading-10">
          {{ numberOfRisks }}
        </div>
        <div class="flex flex-col items-center justify-center">
          <p class="mb-1 rounded-md bg-red-500 px-1.5 py-0.5 text-white">
            {{ numberOfRisks - numberOfRisksLastMonth > 0 ? '+' : ''
            }}{{ numberOfRisks - numberOfRisksLastMonth }}
          </p>
          <p class="text-gray-400">{{ numberOfRisksLastMonth }}</p>
          <p class="text-gray-400">(Last Month)</p>
        </div>
      </div>
    </div>
    <table
      mat-table
      [dataSource]="table$ | async"
      class="mat-elevation-z8 report-table !overflow-hidden !rounded-md !bg-white !shadow-none"
    >
      <ng-container matColumnDef="metric">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Metric
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          <div [innerHTML]="element.metric"></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="now">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Now
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          {{ element.now > 0 ? element.now : '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="was">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Was
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          {{ element.was > 0 ? element.was : '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="notes">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Notes
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          <div [innerHTML]="element.notes"></div>
        </td>
      </ng-container>
      <!-- Header Row Definition -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="!h-10 !text-black"
      ></tr>
      <!-- Row Definition -->
      <tr
        class="!h-11 !text-slate-900 first:!overflow-hidden first:!rounded-t-md"
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="{
          '!bg-slate-200': i % 2 === 0,
          '!bg-slate-300': i % 2 === 1,
        }"
      ></tr>
    </table>
  </div>
</div>
