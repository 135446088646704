<!--should add expiration part if link expired-->
<form
  [formGroup]="startupDataForm"
  class="flex flex-col gap-4"
  *ngIf="!isInValid"
>
  <div
    class="mx-2 mt-2 flex flex-wrap items-center rounded-md bg-slate-900 px-5 py-2"
  >
    <div class="ml-2 text-xl font-medium text-white">
      Apply for an Investment
    </div>
    <button
      class="font-mada ml-auto flex items-center gap-2 rounded-md bg-blue-400 px-8 text-lg font-normal text-slate-900"
      [disabled]="!startupDataForm.valid || applied"
      [matTooltip]="
        !startupDataForm.valid
          ? 'Please fill all data correctly'
          : applied
          ? 'Already applied'
          : null
      "
      [ngClass]="
        !startupDataForm.valid || applied ? 'opacity-50' : 'opacity-100'
      "
      (click)="submit()"
    >
      <p>Submit</p>
      <mat-spinner
        class="!h-4 !w-4 text-slate-900"
        *ngIf="isSubmitting"
      ></mat-spinner>
      <mat-icon
        class="text-slate-900"
        *ngIf="!isSubmitting && applied"
        >check</mat-icon
      >
    </button>
  </div>

  <div
    class="font-mada mx-2 flex flex-col items-center gap-1 text-wrap text-center font-medium"
  >
    <div class="text-3xl text-white">Welcome to {{ slug }}</div>
    <div class="text-base text-gray-400">
      We are delighted to receive your application and are excited about the
      opportunity to explore potential investment.
    </div>
    <div class="text-xl text-white">
      Please fill out the form to help us consider investing in your startup.
    </div>
  </div>

  <!--form-->
  <div class="mx-auto flex w-[70%] flex-col items-center gap-4 md:flex-row">
    <div
      class="flex w-full min-w-56 flex-col gap-3 rounded-xl bg-slate-700 p-4 lg:w-1/5"
    >
      <div
        class="flex rounded-md border-4 border-dashed border-slate-900 px-4 pb-3 pt-4"
      >
        <img
          *ngIf="logoPreview"
          [src]="logoPreview"
          alt="Logo"
          class="mx-auto !h-20 !w-20 object-contain"
        />

        <mat-icon
          *ngIf="!logoPreview"
          class="mx-auto !h-7 !w-7 !text-2xl !text-slate-900 xl:!h-9 xl:!w-9"
          svgIcon="upload-logo"
        ></mat-icon>
      </div>
      <div class="flex flex-col gap-2">
        <button
          class="w-full rounded-md bg-gray-400 py-1 text-center text-base font-normal text-slate-900"
          (click)="browseForLogo()"
          [disabled]="applied"
          [ngClass]="applied ? 'opacity-50' : 'opacity-100'"
          [matTooltip]="applied ? 'Already applied' : null"
        >
          <input
            accept=".png,.svg,.JPG"
            type="file"
            #fileInput
            style="display: none"
            (change)="handleLogoUpload($event)"
          />
          {{ logoPreview ? 'Change' : 'Upload' }} your logo
        </button>
        <p
          class="text-center text-sm font-normal text-gray-400 md:text-[11px] lg:text-xs"
        >
          Supported: JPG, PNG, SVG
        </p>
      </div>
    </div>
    <div class="flex w-full flex-col gap-x-4 gap-y-4 lg:w-4/5 lg:gap-y-12">
      <div class="flex flex-col gap-4 lg:flex-row">
        <div class="font-mada w-full lg:w-[50%]">
          <input
            class="w-full !rounded-md !border-2 !bg-transparent px-3 py-1 text-base font-normal !text-white"
            type="text"
            placeholder="Company Name"
            formControlName="companyName"
            name="companyName"
            [ngClass]="
              startupDataForm.controls.companyName.dirty &&
              !startupDataForm.controls.companyName.valid
                ? '!border-red-700'
                : '!border-slate-700'
            "
          />
        </div>
        <div class="font-mada w-full lg:w-[50%]">
          <input
            class="w-full !rounded-md !border-2 !bg-transparent px-3 py-1 text-base font-normal !text-white"
            type="email"
            placeholder="Company Email"
            formControlName="companyEmail"
            [ngClass]="
              startupDataForm.controls.companyEmail.dirty &&
              !startupDataForm.controls.companyEmail.valid
                ? '!border-red-700'
                : '!border-slate-700'
            "
          />
        </div>
      </div>
      <div class="flex flex-col gap-4 lg:flex-row">
        <div class="font-mada w-full lg:w-[50%]">
          <input
            class="w-full !rounded-md !border-2 !bg-transparent px-3 py-1 text-base font-normal !text-white"
            type="text"
            placeholder="Company Website"
            formControlName="companyWebsite"
            name="companyWebsite"
            [ngClass]="
              startupDataForm.controls.companyWebsite.dirty &&
              !startupDataForm.controls.companyWebsite.valid
                ? '!border-red-700'
                : '!border-slate-700'
            "
          />
        </div>
        <div
          class="font-mada mt-auto flex w-full items-center gap-1 rounded-full border-2 px-1 lg:w-[50%]"
          [ngClass]="
            startupDataForm.controls.linkedInUrl.dirty &&
            !startupDataForm.controls.linkedInUrl.valid
              ? '!border-red-700'
              : 'border-sky-600'
          "
        >
          <mat-icon
            svgIcon="linkedin"
            class="!h-7 !w-7 !text-sky-600"
          ></mat-icon>
          <input
            class="w-full !border-0 !bg-transparent py-1 text-base font-normal !text-white outline-none placeholder:items-center focus:outline-none focus:ring-0"
            type="text"
            placeholder="LinkedIn url"
            formControlName="linkedInUrl"
            name="linkedInUrl"
          />
        </div>
      </div>
    </div>
  </div>

  <!--description-->
  <div class="mx-auto flex w-[70%] flex-col">
    <p class="ml-2 text-gray-400">Company Elevator Pitch:</p>
    <textarea
      class="min-h-[8rem] w-full !rounded-md !border-2 !bg-transparent px-3 text-base font-normal !text-white"
      formControlName="companyDescription"
      name="companyDescription"
      [ngClass]="
        startupDataForm.controls.companyDescription.dirty &&
        !startupDataForm.controls.companyDescription.valid
          ? '!border-red-700'
          : '!border-slate-700'
      "
    ></textarea>
  </div>

  <div class="mx-auto flex w-[70%] flex-col">
    <p class="ml-2 text-gray-400">Number of Employees:</p>
    <select
      class="selection: w-full !rounded-md !border-2 !bg-slate-800 px-3 text-base font-normal !text-white"
      formControlName="numberOfEmployees"
      type="number"
      min="1"
      name="numberOfEmployees"
      [ngClass]="
        startupDataForm.controls.numberOfEmployees.dirty &&
        !startupDataForm.controls.numberOfEmployees.valid
          ? '!border-red-700'
          : '!border-slate-700'
      "
    >
      <option value="VeryLow">1-10</option>
      <option value="Low">11-50</option>
      <option value="LowerNormal">51-100</option>
      <option value="Normal">101-500</option>
      <option value="HigherNormal">501-1000</option>
      <option value="High">1001-10000</option>
      <option value="VeryHigh">10000+</option>
    </select>
  </div>

  <div class="mx-auto flex w-[70%] flex-col">
    <p class="ml-2 text-gray-400">Non-Production Environment:</p>
    <input
      class="w-full !rounded-md !border-2 !bg-transparent px-3 text-base font-normal !text-white"
      formControlName="dastScanUrl"
      name="dastScanUrl"
      [ngClass]="
        startupDataForm.controls.dastScanUrl.dirty &&
        !startupDataForm.controls.dastScanUrl.valid
          ? '!border-red-700'
          : '!border-slate-700'
      "
    />
    <p
      id="helper-text-explanation"
      class="mt-2 text-sm text-gray-500 dark:text-gray-400"
    >
      Might be used for a dynamic application security testing (DAST) scan.
    </p>
  </div>

  <!--upload files-->
  <button
    class="mx-auto flex w-[70%] items-center rounded-lg bg-gray-700 py-2 text-center text-base font-normal text-slate-900 md:py-1"
    (click)="browseForFiles()"
  >
    <input
      type="file"
      #filesUpload
      style="display: none"
      (change)="handleFilesUpload($event)"
      multiple
      accept=".pdf,.png,.jpg,.txt,.pptx"
    />
    <div class="mx-auto flex flex-col items-center gap-4 md:flex-row">
      <mat-icon
        svgIcon="file-uplaod"
        class="!h-8 !w-8"
      ></mat-icon>

      <div class="flex flex-col items-center md:items-start">
        <p class="font-mada text-lg font-medium text-slate-900">Upload Files</p>
        <p class="font-mada text-sm font-normal text-gray-400">
          Max file size 25 MB, (PDFs, Screenshots, Power Point, Etc..)
        </p>
      </div>
    </div>
  </button>
  <div class="mx-auto flex w-[70%] flex-wrap gap-5">
    <div
      class="flex"
      *ngFor="let file of files; let i = index"
    >
      <div
        class="rounded-l-[5px] bg-slate-500 p-2 pb-0.5 pr-2.5"
        [ngClass]="
          checkFile(file) ? 'border-collapse border-b-2 border-b-red-900' : ''
        "
      >
        <mat-icon
          svgIcon="pdf-file"
          class="!size-8"
        ></mat-icon>
      </div>
      <div
        class="-ml-1 flex w-44 items-center justify-between rounded-[5px] bg-slate-700 px-3 py-2 sm:w-60"
        [ngClass]="
          checkFile(file) ? 'border-collapse border-b-2 border-b-red-900' : ''
        "
      >
        <div class="flex flex-col">
          <div class="max-w-28 truncate text-sm text-white sm:max-w-40">
            {{ file.name.split('.').slice(0, -1).join('.') }}
          </div>
          <div
            class="text-xs text-zinc-400"
            [ngClass]="!checkFile(file) ? '' : '!text-red-400'"
          >
            {{
              !checkFile(file)
                ? file.name.split('.').pop()
                : 'File is large (max. 25 MB)'
            }}
          </div>
        </div>
        <button
          class="!h-6"
          (click)="removeFile(file)"
        >
          <mat-icon
            *ngIf="checkFile(file)"
            class="!text-slate-900"
            >close</mat-icon
          >
          <mat-icon
            *ngIf="!checkFile(file)"
            class="!text-slate-900"
            svgIcon="delete"
          ></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!--contact info-->
  <div class="mx-auto flex w-[60%] flex-col gap-2 lg:flex-row lg:items-center">
    <div class="flex items-center gap-2">
      <mat-icon
        class="!h-8 !w-8 !text-blue-400 lg:!h-20 lg:!w-20"
        svgIcon="single-developer"
      ></mat-icon>
      <div class="font-mada min-w-fit text-2xl font-medium !text-blue-400">
        Contact Info
      </div>
    </div>

    <input
      class="ml-0 w-full !rounded-md !border-2 !bg-transparent px-3 py-1 text-base font-normal !text-white lg:ml-2"
      type="text"
      placeholder="Name"
      formControlName="contactName"
      name="contactName"
      [ngClass]="
        startupDataForm.controls.contactName.dirty &&
        !startupDataForm.controls.contactName.valid
          ? '!border-red-700'
          : '!border-slate-700'
      "
    />
    <input
      class="w-full !rounded-md !border-2 !bg-transparent px-3 py-1 text-base font-normal !text-white"
      type="email"
      placeholder="E-mail"
      formControlName="contactEmail"
      name="contactEmail"
      [ngClass]="
        startupDataForm.controls.contactEmail.dirty &&
        !startupDataForm.controls.contactEmail.valid
          ? '!border-red-700'
          : '!border-slate-700'
      "
    />
  </div>
  <div class="mb-3 flex justify-center">
    <button
      class="font-mada flex items-center gap-2 rounded-md bg-blue-400 px-8 text-lg font-normal text-slate-900"
      [disabled]="!startupDataForm.valid || applied"
      [matTooltip]="
        !startupDataForm.valid
          ? 'Please fill all data correctly'
          : applied
          ? 'Already applied'
          : null
      "
      [ngClass]="
        !startupDataForm.valid || applied ? 'opacity-50' : 'opacity-100'
      "
      (click)="submit()"
    >
      <p>Submit</p>
      <mat-spinner
        class="!h-4 !w-4 text-slate-900"
        *ngIf="isSubmitting"
      ></mat-spinner>
      <mat-icon
        class="text-slate-900"
        *ngIf="!isSubmitting && applied"
        >check</mat-icon
      >
    </button>
  </div>
</form>
