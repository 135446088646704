<div>
  <div class="mb-4 flex justify-between bg-slate-800 px-5 py-2 text-white">
    <div>
      <div class="flex gap-1">
        <img
          class="brand w-32 cursor-pointer object-contain text-xl"
          data-test="logo-top"
          *ngIf="account?.logo"
          [src]="account?.logo"
        />
        <div class="text-3xl font-medium">{{ account.companyName }}</div>
      </div>
      <div class="mt-2 font-medium leading-3">
        <span>Generated</span>
        <span
          style="color: #6db4fc"
          class="mx-1"
          >({{ currentDate | date : 'dd/MM/yyyy' }})</span
        >
        <span>for {{ user.email }}</span>
      </div>
    </div>
    <img
      class="brand w-32 cursor-pointer object-contain text-xl"
      data-test="logo-top"
      src="../../../assets/logo.png"
    />
  </div>
</div>
