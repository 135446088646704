import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LetDirective } from '@ngrx/component';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';
import { SpiderChartComponent } from '@codenteam/ui/spider-chart/spider-chart.component';
import { NameValue } from '@codenteam/ui/types/name-value.type';
import { ApiService } from '../../core/api.service';
import { LinesService } from '../../core/lines.service';
import { Utils } from '../../core/utils';
import { TeamsAnalysisReportComponent } from '../teams-analysis-report/teams-analysis-report.component';
import { PieGraphComponent } from '@codenteam/ui/pie-graph/pie-graph.component';
import { Profile } from '@codenteam/portal/graphql';

export interface PeriodicElement {
  metric: string | SafeHtml;
  now: number;
  was: number | string;
  notes: string | SafeHtml;
}
@Component({
  selector: 'codenteam-company-analysis-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    SpiderChartComponent,
    LetDirective,
    ReportHeaderComponent,
    TeamsAnalysisReportComponent,
    PieGraphComponent,
  ],
  templateUrl: './company-analysis-report.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CompanyAnalysisReport implements OnInit {
  displayedColumns: string[] = ['metric', 'now', 'was', 'notes'];
  dataSource: PeriodicElement[] = [];
  teamsThisMonth: NameValue[];
  teamsPreviousMonth: NameValue[];

  @Input()
  runId: string;

  @Output()
  completed = new EventEmitter<boolean>();

  report$: Observable<any>;
  currentDate: string;
  lastMonthDate: string;
  numberOfTeams: number;
  numberOfExEmployees: number;
  spiderTeams: { name: string; color: string; rate1: number; rate2: number }[];
  dataSource$: Observable<PeriodicElement[]>;
  numberOfRisks: number = 0;
  numberOfRisksLastMonth: number = 0;
  numberOfAllmodules: number;
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  linesPerTeam$: Observable<NameValue[]>;
  date =
    new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime() / 1000;

  table$: Observable<
    {
      metric: string | SafeHtml;
      now: number;
      was: string | number;
      notes: SafeHtml;
    }[]
  >;
  constructor(
    private apiService: ApiService,
    private linesService: LinesService,
    private sanitizer: DomSanitizer
  ) {}

  fetchAll() {
    const linesPerTeam$ = this.linesService.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'team',
      runId$: of(this.runId),
    });
    const linesPerProfiles$ = this.linesService.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'profile',
      runId$: of(this.runId),
    });
    const linesPerProfilesLasMonth$ = this.linesService.contributionsPer$({
      range$: of({ start: this.date, end: new Date().getTime() / 1000 }),
      per: 'profile',
      runId$: of(this.runId),
    });

    const exEmployeesInCompany$ = this.apiService.getExEmployeesInRun(
      this.runId
    );

    this.table$ = combineLatest([
      this.apiService.getReportClosestTo30DaysAgo(this.runId),
      this.apiService.countDirectories(this.runId),
      this.apiService.countFiles(this.runId),
      this.linesService.getHighestContributingTeam$(of(this.runId)),
      this.linesService.getHighestContributingProfile$(of(this.runId)),
      this.linesService.getExContribution(of(this.runId)).exContribution$,
      this.apiService.countFilesBeforeLast30Days(this.runId),
      this.apiService.countDirectoriesBeforeLast30Days(this.runId),
      this.apiService.getProfilesForReport(this.runId),
      this.apiService.getTeamsWithProfilesForReport(this.runId),
      this.apiService.countProfilesModulesAndDarkModules(this.runId),
      this.apiService.countTeamsModulesAndDarkModules(this.runId),
      linesPerTeam$,
      linesPerProfilesLasMonth$,
      linesPerProfiles$,
      exEmployeesInCompany$,
    ]).pipe(
      map(
        ([
          report,
          directories,
          files,
          highestContributingTeam,
          highestContributingProfile,
          exContribution,
          numberOfFilesBeforeLast30Days,
          numberOfDirectoriesBeforeLast30Days,
          profiles,
          teams,
          profilesDarkModules,
          teamsDarkModules,
          linesPerTeam,
          linesPerProfilesLasMonth,
          linesPerProfiles,
          exEmployeesInCompany,
        ]) => {
          //get number of ex-employees in company
          this.numberOfExEmployees = exEmployeesInCompany.length;
          //get exContribution (now) value
          const exContributionValue = exContribution[1]
            ? exContribution[1].value
            : 0;

          //get exContribution (was) value

          const exContributionBefore30Days = Utils.resolveHistoricalValue(
            report,
            'ex',
            'total',
            1
          ).value;

          //get the total number of lines (now) value

          const numberOfLines = exContribution.reduce(
            (sum: number, ex: any) => sum + ex.value,
            0
          );

          //get the total number of lines (was) value

          const numberOfLinesBefore30Days = report.code.total.profile.reduce(
            (sum: number, profile: any) => sum + profile.value,
            0
          );

          //fill spiderTeams array for spider chart if number of team is more than 2

          if (teams.length > 2) {
            this.spiderTeams = linesPerTeam.map((team) => {
              const teamLinesBeforeMonth = Utils.resolveHistoricalValue(
                report,
                'profile',
                'total',
                team.id
              ).value;
              const rateThisMonth = parseFloat(
                ((team.value / numberOfLines) * 100).toFixed()
              );
              const rateLastMonth = parseFloat(
                (
                  (teamLinesBeforeMonth / numberOfLinesBefore30Days) *
                  100
                ).toFixed()
              );
              return {
                name: team.name,
                color: team.color,
                rate1: rateThisMonth,
                rate2: rateLastMonth,
              };
            });
          } else {
            //fill teamsThisMonth and teamsPreviousMonth arrays for pie chart if number of team is equak or less than 2

            this.teamsThisMonth = linesPerTeam.map((team) => {
              let rateNow = 0;
              rateNow = parseFloat(
                ((team.value / numberOfLines) * 100).toFixed()
              );
              return {
                id: team.id,
                name: team.name,
                value: rateNow,
                color: team.color,
              } as NameValue;
            });
            this.teamsPreviousMonth = teams.map((team) => {
              const teamLastMonth = Utils.resolveHistoricalValue(
                report,
                'profile',
                'total',
                team.id
              );
              let rate = 0;
              if (numberOfLinesBefore30Days > 0) {
                rate = parseFloat(
                  (
                    (teamLastMonth.value / numberOfLinesBefore30Days) *
                    100
                  ).toFixed()
                );
              }

              return {
                id: team.id,
                name: team.name,
                value: rate,
                color: team.color,
              } as NameValue;
            });
          }

          //get number of teams (now) value

          this.numberOfTeams = teams.length;

          //get number of teams (was) value

          const numberOfTeamsBefore30Days = report.code.total.team.length;

          //get number of profiles (was) value

          const numberOfProfilesBefore30Days = report.code.total.profile.length;

          //get Average Contribution per Team (now) value

          const averageContributionPerTeam = Math.floor(
            numberOfLines / this.numberOfTeams
          );

          //get Average Contribution per Team (was) value

          const averageContributionPerTeamBefore30Days = Math.floor(
            numberOfLinesBefore30Days / numberOfTeamsBefore30Days
          );

          //get Average Contribution per Profile (now) value

          const averageContributionPerProfile = Math.floor(
            numberOfLines / profiles.length
          );

          //get Average Contribution per Profile (was) value

          const averageContributionPerProfileBefore30Days = Math.floor(
            numberOfLinesBefore30Days / numberOfProfilesBefore30Days
          );

          //get heighest contributing team (was) value

          const highestContributingTeamBeforeLast30Days =
            report.code.total.team.reduce((max, item) =>
              item.value > max.value ? item : max
            );

          //get heighest contributing profile (was) value

          const highestContributingProfileBeforeLast30Days =
            report.code.total.profile.reduce((max, item) =>
              item.value > max.value ? (max = item) : max
            );
          const contributionForhighestContributingProfile =
            highestContributingProfileBeforeLast30Days.value;

          //calculate avarage work factor (now) value

          const averageWorkFactor =
            profiles.reduce((sum: number, profile: any) => {
              const currentProfile = linesPerProfilesLasMonth.find(
                (prof) => prof.id === profile.id
              );
              const workFactor =
                profile.hoursPerWeek && profile.costPerHour && currentProfile
                  ? currentProfile.value /
                    (profile.hoursPerWeek * 4 * profile.costPerHour)
                  : 0;
              return sum + workFactor;
            }, 0) / profiles.length;

          ////////////////////////////////////////////////////////////////////////
          //second problem: we are calculating the avarege work factor (was) value
          //by hoursPerWeek and costPerHour (now) value
          //because currently we can't get what their value was last month
          ////////////////////////////////////////////////////////////////////////

          //calculate avarage work factor (was) value

          const averageWorkFactorBeforeLast30Days =
            profiles.reduce((sum: number, profile: any) => {
              const workFactor =
                profile.hoursPerWeek && profile.costPerHour
                  ? Utils.resolveHistoricalValue(
                      report,
                      'profile',
                      'month',
                      profile.id
                    ).value /
                    (profile.hoursPerWeek * 4 * profile.costPerHour)
                  : 0;
              return sum + workFactor;
            }, 0) / profiles.length;

          //calculate heighest team work factor (now) value

          const highestTeamForWorkFactor = teams.reduce(
            (highest, team) => {
              const teamLines = team.profiles.reduce(
                (sum: number, profile: any) => {
                  const curProfile = linesPerProfilesLasMonth.find(
                    (item) => item.id === profile.id
                  );
                  return sum + curProfile?.value;
                },
                0
              );
              const costOfHoursPerMonth = team.profiles.reduce(
                (sum, profile) => {
                  if (profile.hoursPerWeek && profile.costPerHour) {
                    return sum + profile.hoursPerWeek * 4 * profile.costPerHour;
                  }
                  return sum;
                },
                0
              );
              const workFactor =
                costOfHoursPerMonth > 0 && teamLines
                  ? teamLines / costOfHoursPerMonth
                  : 0;
              if (workFactor > highest.workFactor) {
                return { team, workFactor };
              }

              return highest;
            },
            { team: teams[0], workFactor: 0 }
          );

          //calculate heighest team work factor (was) value

          const highestTeamForWorkFactorBeforeLast30Days =
            report.code.month.team.reduce(
              (highest, team) => {
                const teamLines = team.value;

                const costOfHoursPerMonthBeforeLast30Days = profiles.reduce(
                  (sum, profile) => {
                    if (profile.teamId === team.id) {
                      if (profile.hoursPerWeek && profile.costPerHour) {
                        return (
                          sum + profile.hoursPerWeek * 4 * profile.costPerHour
                        );
                      }
                    }
                    return sum;
                  },
                  0
                );

                const workFactor =
                  teamLines && costOfHoursPerMonthBeforeLast30Days > 0
                    ? teamLines / costOfHoursPerMonthBeforeLast30Days
                    : 0;

                if (workFactor > highest.workFactor) {
                  return { team, workFactor };
                }

                return highest;
              },
              { team: report.code.month.team[0], workFactor: 0 }
            );

          //calculate heighest profile work factor (now) value

          const WorkFactorForProfiles = linesPerProfilesLasMonth.reduce(
            (highest, profile) => {
              const currentProfile = profiles.find(
                (prof) => prof.id === profile.id
              );
              const workFactor =
                currentProfile.hoursPerWeek && currentProfile.costPerHour
                  ? profile.value /
                    (currentProfile.hoursPerWeek *
                      4 *
                      currentProfile.costPerHour)
                  : 0;

              if (workFactor > highest.workFactor) {
                return { profile, workFactor };
              }

              return highest;
            },
            { profile: linesPerProfilesLasMonth[0], workFactor: 0 }
          );

          //calculate heighest profile work factor (was) value

          const HighestProfileInWorkFactorBeforeLast30Days =
            report.code.month.profile.reduce(
              (highest, profile) => {
                const currentProfile = profiles.find(
                  (prof) => prof.id === profile.id
                );
                const workFactor =
                  currentProfile.hoursPerWeek && currentProfile.costPerHour
                    ? profile.value /
                      (currentProfile.hoursPerWeek *
                        4 *
                        currentProfile.costPerHour)
                    : 0;

                if (workFactor > highest.workFactor) {
                  return { profile, workFactor };
                }

                return highest;
              },
              { profile: report.code.month.profile[0], workFactor: 0 }
            );

          //calculate number of modules owned by ex-employees (now) value

          const numberOfOwnedModulesByExEmployees =
            profilesDarkModules.group.reduce(
              (sum: number, profile: any) =>
                profile.ex === true ? sum + profile.darkModulesNumber : sum,
              0
            );
          this.numberOfRisks += numberOfOwnedModulesByExEmployees;
          this.numberOfAllmodules = profilesDarkModules.allModules;
          //calculate number of modules owned by employees (now) value

          const numberOfOwnedModulesByCurrentEmployees =
            profilesDarkModules.group.reduce(
              (sum: number, profile: any) =>
                profile.ex === false ? sum + profile.darkModulesNumber : sum,
              0
            );

          //calculate number of modules owned by team (now) value

          const numberOfOwnedModulesByTeams = teamsDarkModules.group.reduce(
            (sum: number, team: any) => sum + team.darkModulesNumber,
            0
          );

          //add risk if ex employees contibution persentage is more than 50%

          const exOwnerShipPercentage = Math.floor(
            Math.min((exContributionValue * 100) / numberOfLines, 100)
          );
          let exContributionNote = '';
          if (exOwnerShipPercentage >= 50) {
            this.numberOfRisks++;
            exContributionNote = `<div><span class="text-red-800">High-risk</span> code ownership (<span class="text-red-800">${exOwnerShipPercentage.toFixed(
              2
            )}%</span>)</div>`;
          }

          //add risk if ex employees contibution persentage is more than 50% (last month)

          const exOwnerShipPercentageLastMonth = Math.floor(
            Math.min(
              (exContributionBefore30Days * 100) / numberOfLinesBefore30Days,
              100
            )
          );
          if (exOwnerShipPercentageLastMonth >= 50) {
            this.numberOfRisksLastMonth++;
          }
          //add risk if ex employee contibution persentage is more than 50%

          let profileOwnCompanyCode;
          let profileOwnCompanyCodePersentage;
          linesPerProfiles.forEach((profile) => {
            const profileContributionPersentage = Math.floor(
              Math.min((profile.value * 100) / numberOfLines, 100)
            );
            if (profileContributionPersentage >= 50) {
              this.numberOfRisks++;
              profileOwnCompanyCode = profile;
              profileOwnCompanyCodePersentage = profileContributionPersentage;
            }
          });

          //add risk if current employee contibution persentage is more than 50% (last Month)

          report.code.total.profile.forEach((profile) => {
            const profileContributionPersentageLastMonth = Math.floor(
              Math.min((profile.value * 100) / numberOfLinesBefore30Days, 100)
            );
            if (profileContributionPersentageLastMonth >= 50) {
              this.numberOfRisksLastMonth++;
            }
          });

          const numberOfLinesNote = this.calculatePercentage(
            numberOfLines,
            numberOfLinesBefore30Days,
            false
          );

          const averageContributionPerTeamNote = this.calculatePercentage(
            averageContributionPerTeam,
            averageContributionPerTeamBefore30Days,
            false
          );

          const averageContributionPerProfileNote = this.calculatePercentage(
            averageContributionPerProfile,
            averageContributionPerProfileBefore30Days,
            false
          );

          const numberOfFilesNote = this.calculatePercentage(
            files,
            numberOfFilesBeforeLast30Days,
            false
          );

          const numberOfDirectoriesNote = this.calculatePercentage(
            directories,
            numberOfDirectoriesBeforeLast30Days,
            false
          );

          const highestContributingTeamNote = this.highestContributingNoteFn(
            highestContributingTeam,
            highestContributingTeamBeforeLast30Days
          );

          const highestContributingProfileNote =
            this.highestContributingProfileNoteFn(
              highestContributingProfile,
              highestContributingProfileBeforeLast30Days,
              profileOwnCompanyCode,
              profileOwnCompanyCodePersentage
            );

          const averageWorkFactorNote = this.calculatePercentage(
            averageWorkFactor,
            averageWorkFactorBeforeLast30Days,
            false
          );

          const highestProfileInWorkFactorNote = this.calculatePercentage(
            WorkFactorForProfiles.workFactor,
            HighestProfileInWorkFactorBeforeLast30Days.workFactor,
            false
          );

          const highestTeamInWorkFactorNote = this.calculatePercentage(
            highestTeamForWorkFactor.workFactor,
            highestTeamForWorkFactorBeforeLast30Days.workFactor,
            false
          );
          this.completed.emit(true);
          return [
            {
              metric: 'Number of Directories',
              now: directories,
              was: numberOfDirectoriesBeforeLast30Days,
              notes: numberOfDirectoriesNote,
            },
            {
              metric: 'Number of Files',
              now: files,
              was: numberOfFilesBeforeLast30Days,
              notes: numberOfFilesNote,
            },
            {
              metric: 'Number of Lines',
              now: numberOfLines,
              was: numberOfLinesBefore30Days,
              notes: numberOfLinesNote,
            },
            {
              metric: 'Number of Teams',
              now: this.numberOfTeams,
              was: numberOfTeamsBefore30Days,
              notes: '',
            },
            {
              metric: 'Number of Developers',
              now: profiles.length,
              was: numberOfProfilesBefore30Days,
              notes: '',
            },
            {
              metric: 'Average Contribution per Team',
              now: averageContributionPerTeam,
              was: averageContributionPerTeamBefore30Days,
              notes: averageContributionPerTeamNote,
            },
            {
              metric: 'Average Contribution per Dev',
              now: averageContributionPerProfile,
              was: averageContributionPerProfileBefore30Days,
              notes: averageContributionPerProfileNote,
            },
            {
              metric: this.sanitizeHtml(
                `Highest Contributing Team : <span style="color: ${highestContributingTeam.color};">${highestContributingTeam.name}</span>`
              ),
              now: highestContributingTeam.value,
              was: highestContributingTeamBeforeLast30Days.value,
              notes: highestContributingTeamNote,
            },
            {
              metric: this.sanitizeHtml(
                `Highest Contributing Developer : <span style="color: ${highestContributingProfile.color};">${highestContributingProfile.name}</span>`
              ),
              now: highestContributingProfile.value,
              was: contributionForhighestContributingProfile,
              notes: highestContributingProfileNote,
            },

            {
              metric: 'Ex-Employee Ownership',
              now: exContributionValue,
              was: exContributionBefore30Days,
              notes: exContributionNote,
            },
            {
              metric: 'Modules Owned By Ex-Employees',
              now: numberOfOwnedModulesByExEmployees,
              was: 0,
              notes: this.ownedModulesNoteFn(
                'ex-Employee',
                numberOfOwnedModulesByExEmployees
              ),
            },
            {
              metric: 'Modules owned by a single developer',
              now: numberOfOwnedModulesByCurrentEmployees,
              was: 0,
              notes: this.ownedModulesNoteFn(
                'developer',
                numberOfOwnedModulesByCurrentEmployees
              ),
            },
            {
              metric: 'Modules with no clear ownership',
              now: this.numberOfAllmodules - numberOfOwnedModulesByTeams,
              was: 0,
              notes: this.ownedModulesNoteFn(
                '',
                this.numberOfAllmodules - numberOfOwnedModulesByTeams
              ),
            },
            {
              metric: 'Modules properly owned by single team',
              now: numberOfOwnedModulesByTeams,
              was: 0,
              notes: this.ownedModulesNoteFn(
                'team',
                numberOfOwnedModulesByTeams
              ),
            },
            // {
            //   metric: Code Dilution %,
            //   now: parseFloat(
            //     ((codeDilution.value / codeDilution.total) * 100).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            // {
            //   metric: this.sanitizeHtml(
            //     Highest Team Code Dilution : <span style="color: ${highestTeamDilution.maxTeam.color};">${highestTeamDilution.maxTeam.name}</span>
            //   ),
            //   now: parseFloat(
            //     (
            //       (highestTeamDilution.maxDilution /
            //         highestTeamDilution.maxTotal) *
            //       100
            //     ).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            // {
            //   metric: this.sanitizeHtml(
            //     Highest Developer Code Dilution : <span style="color: ${highestProfileDilution.maxProfile.color};">${highestProfileDilution.maxProfile.name}</span>
            //   ),
            //   now: parseFloat(
            //     (
            //       (highestProfileDilution.maxDilution /
            //         highestProfileDilution.maxTotal) *
            //       100
            //     ).toFixed(2)
            //   ),
            //   was: '-',
            //   notes: '',
            // },
            {
              metric: 'Average Work Factor',
              now: parseFloat(averageWorkFactor.toFixed(2)),
              was: parseFloat(averageWorkFactorBeforeLast30Days.toFixed(2)),
              notes: averageWorkFactorNote,
            },
            {
              metric: this.sanitizeHtml(
                `Highest Team in Work Factor : <span style="color: ${
                  highestTeamForWorkFactor.team.color
                }">${
                  highestTeamForWorkFactor.workFactor > 0
                    ? highestTeamForWorkFactor.team.name
                    : ''
                }</span>`
              ),
              now: parseFloat(highestTeamForWorkFactor.workFactor.toFixed(2)),
              was: parseFloat(
                highestTeamForWorkFactorBeforeLast30Days.workFactor.toFixed(2)
              ),
              notes: highestTeamInWorkFactorNote,
            },
            ...(WorkFactorForProfiles.profile
              ? [
                  {
                    metric: this.sanitizeHtml(
                      `Highest Developer in Work Factor : <span style="color: ${
                        WorkFactorForProfiles.profile.color
                      }">${
                        WorkFactorForProfiles.workFactor > 0
                          ? WorkFactorForProfiles.profile.name
                          : ''
                      }</span>`
                    ),
                    now: parseFloat(
                      WorkFactorForProfiles.workFactor.toFixed(2)
                    ),
                    was: parseFloat(
                      HighestProfileInWorkFactorBeforeLast30Days.workFactor.toFixed(
                        2
                      )
                    ),
                    notes: highestProfileInWorkFactorNote,
                  },
                ]
              : []),
          ];
        }
      )
    );
  }

  ngOnInit(): void {
    this.getDate();
    this.fetchAll();
  }

  calculatePercentage(now: number, was: number, isRisk: boolean) {
    if (isNaN(now) || isNaN(was) || was === 0) return '';

    const difference = now - was;
    const percentage = Math.abs((difference / was) * 100);

    if (percentage === 0) return '';

    const arrowColor =
      (isRisk && difference < 0) || (!isRisk && difference > 0)
        ? 'border-lime-600'
        : 'border-red-800';
    const arrowDirection = difference > 0 ? 'rotate-[-135deg]' : 'rotate-45';

    return this.sanitizeHtml(`
      <div class="border-solid ${arrowColor} border-t-0 border-r-[3px] border-b-[3px] border-l-0 inline-block p-[3px] ${arrowDirection} mr-2"></div>
      ${percentage.toFixed(2)}%
    `);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  highestContributingNoteFn(
    highestContributing: any,
    highestContributingBeforeLast30Days: any
  ) {
    if (highestContributing.id !== highestContributingBeforeLast30Days.id) {
      return this.sanitizeHtml(
        ` <div><span style="color: ${highestContributing.color}">${highestContributing.name}</span> Overtook <span style="color: ${highestContributingBeforeLast30Days.color}">${highestContributingBeforeLast30Days.name}</span></div>`
      );
    } else {
      return '';
    }
  }
  highestContributingProfileNoteFn(
    highestContributing: any,
    highestContributingBeforeLast30Days: any,
    OwnCompanyCode: any,
    OwnCompanyCodePersentage: any
  ) {
    if (OwnCompanyCode) {
      return this.sanitizeHtml(
        `<div><span class="text-red-800">High-risk </span><span style="color: ${
          OwnCompanyCode.color
        }">${
          OwnCompanyCode.name
        } </span>code ownership (<span class="text-red-800">${OwnCompanyCodePersentage.toFixed(
          2
        )}%</span>)</div>`
      );
    } else if (
      highestContributing.id !== highestContributingBeforeLast30Days.id
    ) {
      return this.sanitizeHtml(
        ` <div><span style="color: ${highestContributing.color}">${highestContributing.name}</span> Overtook <span style="color: ${highestContributingBeforeLast30Days.color}">${highestContributingBeforeLast30Days.name}</span></div>`
      );
    } else {
      return '';
    }
  }
  ownedModulesNoteFn(conditon: string, ownedModules: number) {
    const percentage = Math.floor(
      Math.min((ownedModules * 100) / this.numberOfAllmodules, 100)
    );
    if (conditon === 'ex-Employee' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-red-800">High-risk </span><span>there is (<span class="text-red-800">${percentage.toFixed(
          2
        )}%</span>) of modules owned by ex-Employees </span></div>`
      );
    } else if (conditon === 'developer' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-orange-500">Medium-risk </span><span>there is (<span class="text-orange-500">${percentage.toFixed(
          2
        )}%</span>) of modules owned by single developer </span></div>`
      );
    } else if (conditon === 'team' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-lime-500">No-risk </span><span>there is (<span class="text-lime-500">${percentage.toFixed(
          2
        )}%</span>) of modules owned by single team </span> </div>`
      );
    } else if (conditon === '' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-yellow-500">Low-risk </span><span>there is (<span class="text-yellow-500">${percentage.toFixed(
          2
        )}%</span>) of modules with no clear ownership </span> </div>`
      );
    } else {
      return '';
    }
  }
  getDate() {
    const today = new Date();
    const day = today.getDate();
    const lastMonth = today.getMonth();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    this.lastMonthDate = `${lastMonth}/${day}/${year};`;
    this.currentDate = ` ${month}/${day}/${year};`;
  }
}
